import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip'
import type { ReactElement } from 'react'

const CustomStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

export const Chips = ({
  chips,
  display,
}: {
  chips: Array<{
    color?: 'primary' | 'secondary'
    label: string
    sx?: any
    key: string
    icon: ReactElement
    tooltip?: string
  }>
  display: number
}) => {
  const displayedChips = chips.slice(0, display)
  const hiddenChips = chips.slice(display)
  return (
    <Stack spacing={1} direction='row' alignItems='center' sx={{ maxWidth: '200px' }}>
      {displayedChips?.map((chip) =>
        chip.tooltip ? (
          <CustomStyledTooltip arrow key={chip.key} title={chip.tooltip}>
            <Chip
              color={chip.color}
              label={chip.label}
              sx={{
                ...chip.sx,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '175px',
              }}
              icon={chip.icon}
            />
          </CustomStyledTooltip>
        ) : (
          <Chip
            key={chip.key}
            color={chip.color}
            label={chip.label}
            sx={{
              ...chip.sx,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '175px',
            }}
            icon={chip.icon}
          />
        )
      )}
      {hiddenChips.length > 0 && (
        <CustomStyledTooltip
          arrow
          title={
            <Box
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '8px',
              }}>
              {hiddenChips.map((chip) => (
                <Chip
                  key={chip.key}
                  color={chip.color}
                  label={chip.label}
                  sx={chip.sx}
                  icon={chip.icon}
                />
              ))}
            </Box>
          }>
          <Chip label={`+${hiddenChips.length}`} sx={{ backgroundColor: 'white' }} />
        </CustomStyledTooltip>
      )}
    </Stack>
  )
}
