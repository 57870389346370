import { type ICompaniesQuery, useCompaniesQuery } from '@invisible/concorde/gql-client'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import type { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { debounce, isEmpty, uniqBy } from 'lodash'
import { useMemo, useState } from 'react'

type Company = ICompaniesQuery['companies']['edges'][number]['node']

export const CompanyFilter = ({
  item,
  applyValue,
  enabled,
}: GridFilterInputValueProps & { enabled: boolean }) => {
  const [searchValue, setSearchValue] = useState<string>()

  const { data, isLoading } = useCompaniesQuery(undefined, {
    enabled,
  })

  const { data: searchedCompaniesData, isLoading: isSearching } = useCompaniesQuery(
    {
      filters: { ...(searchValue ? { name: { iContains: searchValue } } : {}) },
    },
    { enabled: !isEmpty(searchValue) }
  )

  const companies = useMemo(
    () => data?.companies.edges?.map(({ node }) => node) ?? [],
    [data?.companies.edges]
  )

  const searchedCompanies = useMemo(
    () => searchedCompaniesData?.companies.edges?.map(({ node }) => node) ?? [],
    [searchedCompaniesData?.companies.edges]
  )

  const debouncedInputChange = debounce((_, newInputValue: string) => {
    if (!isEmpty(newInputValue)) {
      setSearchValue(newInputValue)
    }
  }, 300)

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          filterSelectedOptions
          includeInputInList
          loading={isLoading || isSearching}
          size='small'
          id='company-filter'
          limitTags={2}
          options={uniqBy([...companies, ...searchedCompanies], 'id')}
          value={item.value as Company[]}
          getOptionLabel={(option) => option.name ?? ''}
          onChange={(event, newValue) => {
            const values = newValue?.map((v) => ({ id: v.id, name: v.name }))
            applyValue({ ...item, value: uniqBy(values, 'id') })
          }}
          inputValue={searchValue}
          onInputChange={debouncedInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Filter by company...'
              placeholder='Select Company'
              size='small'
              fullWidth
            />
          )}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                variant='outlined'
                label={option.name}
                key={index}
              />
            ))
          }
        />
      </FormControl>
    </Box>
  )
}
