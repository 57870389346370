import { logger } from '@invisible/logger/client'
import { useContext, useMutation } from '@invisible/trpc/client'
import { useToasts } from '@invisible/ui/toasts'
import type { PrismaAll } from '@invisible/ultron/prisma'
import CheckIcon from '@mui/icons-material/Check'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import SendIcon from '@mui/icons-material/Send'
import UndoIcon from '@mui/icons-material/Undo'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import {
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
  gridPageSizeSelector,
  GridRowId,
  type GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  selectedGridRowsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import { compact, isEmpty } from 'lodash'
import pMap from 'p-map'
import { Dispatch, SetStateAction, useState } from 'react'

export type ISelectedBillRow = {
  id: string
  userEmail: string
  status?: PrismaAll.BillPaymentStatus | null
  manticoreBillId: string
  drafterEmail: string
  name: string
}

const getSelectedRowsToExport = ({ apiRef }: GridCsvGetRowsToExportParams): GridRowId[] => {
  const selectedRowIds = selectedGridRowsSelector(apiRef)
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys())
  }

  return gridFilteredSortedRowIdsSelector(apiRef)
}

export const CustomToolbar = ({
  selectedBillRows,
  setRowSelectionModel,
  setIsDrafting,
  setRefetch,
}: {
  selectedBillRows?: ISelectedBillRow[]
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel | undefined>>
  setIsDrafting: Dispatch<SetStateAction<boolean>>
  setRefetch: Dispatch<SetStateAction<boolean>>
}) => {
  const reactQueryContext = useContext()
  const apiRef = useGridApiContext()
  const { addToast } = useToasts()
  const [isPreApproving, setIsPreApproving] = useState(false)
  const [isUnapproving, setIsUnapproving] = useState(false)

  const handleExport = () =>
    apiRef.current.exportDataAsCsv({
      getRowsToExport: getSelectedRowsToExport,
    })

  const { mutateAsync: preApproveForBill } = useMutation('billEvent.preApproveForBill')

  const { mutateAsync: unapproveForBill } = useMutation('billEvent.unapproveForBill')

  const handlePreApproveAllBillEvents = async () => {
    setIsPreApproving(true)
    await pMap(
      compact(selectedBillRows),
      async (selectedRow) =>
        preApproveForBill({ billId: selectedRow?.manticoreBillId as string }).catch((err) => {
          addToast(`Failed to pre-approve bill for ${selectedRow?.name}`, {
            appearance: 'error',
          })
          logger.error(`Failed to pre-approve bill for ${selectedRow?.name}`, err)
        }),
      { concurrency: 10 }
    )

    setIsPreApproving(false)

    reactQueryContext.invalidateQueries('bill.findManyForCycle')
    reactQueryContext.invalidateQueries('bill.findByUserAndCycle')

    setRowSelectionModel([])
    addToast('Successfully pre-approved all bill events for the selected bills', {
      appearance: 'success',
    })
    setRefetch(true)
  }

  const handleUnapproveAllBillEvents = async () => {
    setIsUnapproving(true)
    await pMap(
      compact(selectedBillRows),
      async (selectedRow) => {
        const isRedrafting =
          selectedRow.status === 'partially_drafted' || selectedRow.status === 'drafted'
        await unapproveForBill({ billId: selectedRow?.manticoreBillId as string }).catch((err) => {
          addToast(`Failed to unapprove bill for ${selectedRow?.name}`, {
            appearance: 'error',
          })
          logger.error(`Failed to unapprove bill for ${selectedRow?.name}`, err)
        })
        if (isRedrafting) {
          await axios
            .post('/api/netsuite/draftBill', {
              billId: selectedRow?.manticoreBillId as string,
              drafterEmail: selectedRow.drafterEmail as string,
            })
            .catch((err) => {
              addToast(`Failed to undraft bill for ${selectedRow?.name}`, {
                appearance: 'error',
              })
              logger.error(`Failed to undraft bill for ${selectedRow?.name}`, err)
            })
        }
      },
      { concurrency: 3 }
    )

    setIsUnapproving(false)

    reactQueryContext.invalidateQueries('bill.findManyForCycle')
    reactQueryContext.invalidateQueries('bill.findByUserAndCycle')

    setRowSelectionModel([])
    addToast('Successfully unapproved all bill events for the selected bills', {
      appearance: 'success',
    })
    setRefetch(true)
  }

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <div className='flex items-center gap-2'>
        <Button
          size='small'
          startIcon={<SendIcon />}
          disabled={isEmpty(selectedBillRows)}
          onClick={() => setIsDrafting(true)}>
          Send to Finance
        </Button>
        <LoadingButton
          size='small'
          startIcon={<CheckIcon />}
          loading={isPreApproving}
          disabled={(selectedBillRows ?? []).every((row) => row.status === 'drafted')}
          onClick={handlePreApproveAllBillEvents}>
          Approve All Pending
        </LoadingButton>
        <LoadingButton
          size='small'
          startIcon={<UndoIcon />}
          loading={isUnapproving}
          disabled={isEmpty(selectedBillRows)}
          onClick={handleUnapproveAllBillEvents}>
          Unapprove
        </LoadingButton>
        <Divider flexItem variant='middle' orientation='vertical' />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button size='small' startIcon={<SaveAltIcon />} onClick={handleExport}>
          Export{' '}
          {selectedBillRows?.length
            ? `(${
                selectedBillRows.length === gridPageSizeSelector(apiRef)
                  ? 'All'
                  : selectedBillRows.length
              })`
            : ''}
        </Button>
      </div>

      <div className='flex items-center gap-2'>
        <GridToolbarQuickFilter autoFocus placeholder='Find...' />
      </div>
    </GridToolbarContainer>
  )
}
