import { withAuth } from '@invisible/authentication/client'
import { withAbilityContext } from '@invisible/authorization/client'
import { withTRPC } from '@invisible/trpc/client'
import { CustomQueryParamProvider } from '@invisible/ui/hooks/use-query-params'

import { ManticoreAgentDashboard } from '../views/manticoreDashboard'

const AgentsPage = () => (
  <CustomQueryParamProvider>
    <ManticoreAgentDashboard />
  </CustomQueryParamProvider>
)

export default withAuth(withTRPC(withAbilityContext(AgentsPage)))
