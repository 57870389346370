export const categories = [
  { id: 'management_cost', name: 'Management Cost' },
  { id: 'incentive', name: 'Incentive' },
  { id: 'off_platform_work', name: 'Off Platform Work' },
  { id: 'on_platform_work', name: 'On Platform Work' },
  { id: 'adjustment', name: 'Adjustment' },
] as const

export const statusSortOrder: Record<string, number> = {
  Unchecked: 1,
  'Partially Checked': 2,
  'Ready to Send': 3,
  'Sent to Finance': 4,
}

export const HOURS_THRESHOLD_BEFORE_NEW_CYCLE_STARTS = 24 // One day
