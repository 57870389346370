import {
  type IProcessesQuery,
  IProcessStatusEnum,
  useProcessesQuery,
} from '@invisible/concorde/gql-client'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import type { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { debounce, isEmpty, uniqBy } from 'lodash'
import { useMemo, useState } from 'react'

type Process = IProcessesQuery['processes']['edges'][number]['node']

export const ProcessFilter = ({
  item,
  applyValue,
  enabled,
}: GridFilterInputValueProps & { enabled: boolean }) => {
  const [searchValue, setSearchValue] = useState<string>()

  const { data, isLoading } = useProcessesQuery(undefined, {
    enabled,
  })

  const { data: searchedProcessesData, isLoading: isSearching } = useProcessesQuery(
    {
      filters: {
        ...(searchValue ? { name: { iContains: searchValue } } : {}),
        status: { inList: [IProcessStatusEnum.Active] },
      },
    },
    {
      enabled: !isEmpty(searchValue),
    }
  )

  const processes = useMemo(
    () => data?.processes.edges?.map(({ node }) => node) ?? [],
    [data?.processes.edges]
  )

  const searchedProcesses = useMemo(
    () => searchedProcessesData?.processes.edges?.map(({ node }) => node) ?? [],
    [searchedProcessesData?.processes.edges]
  )

  const debouncedInputChange = debounce((_, newInputValue: string) => {
    if (!isEmpty(newInputValue)) {
      setSearchValue(newInputValue)
    }
  }, 300)

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          filterSelectedOptions
          includeInputInList
          loading={isLoading || isSearching}
          size='small'
          id='process-filter'
          limitTags={2}
          options={uniqBy([...processes, ...searchedProcesses], 'id')}
          value={item.value as Process[]}
          getOptionLabel={(option) => option.name ?? ''}
          onChange={(event, newValue) => {
            const values = newValue?.map((v) => ({ id: v.id, name: v.name }))
            applyValue({ ...item, value: uniqBy(values, 'id') })
          }}
          inputValue={searchValue}
          onInputChange={debouncedInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Filter by process...'
              placeholder='Select Process'
              size='small'
              fullWidth
            />
          )}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                variant='outlined'
                label={option.name}
                key={index}
              />
            ))
          }
        />
      </FormControl>
    </Box>
  )
}
