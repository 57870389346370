import { secondsToHMS } from '@invisible/common/date'
import { format } from '@invisible/ui/helpers'
import { adminTheme } from '@invisible/ui/mui-theme-v2'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BillEventMeta } from '@invisible/ultron/zod'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { DataGridPro, type GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { isEmpty } from 'lodash'
import { Dispatch, SetStateAction, useMemo } from 'react'

import { getBillEventStatusColor, getBillEventStatusLabel } from './helpers'

export type IBillEvent = NonNullable<
  inferQueryOutput<'bill.findByUserAndCycle'>
>['bill']['billEvents'][number]

const BillEvents = ({
  parentRowId,
  billEvents,
  selectedBillEventIds,
  setSelectedBillEventIds,
  setSelectedBillEventParentRows,
}: {
  parentRowId: number
  billEvents: IBillEvent[]
  selectedBillEventIds?: string[]
  setSelectedBillEventIds: Dispatch<SetStateAction<string[]>>
  setSelectedBillEventParentRows: Dispatch<SetStateAction<GridRowSelectionModel | undefined>>
}) => {
  const rows = useMemo(
    () =>
      billEvents.map((event) => ({
        id: event.id,
        description: event?.description,
        baseRunId: event?.baseRunId,
        stepRunId: event?.stepRunId,
        multiplier: (event?.meta as BillEventMeta.TSchema)?.multiplier,
        rate: (event?.meta as BillEventMeta.TSchema)?.rate,
        timeTrackingEnabled:
          event?.paymentConfig?.timeTrackingEnabled ??
          (event?.meta as BillEventMeta.TSchema)?.isTimeTracking,
        amount: format.formatMillicents(event?.amount),
        status: getBillEventStatusLabel(event?.status),
        createdAt: event?.createdAt?.toISOString(),
      })),
    [billEvents]
  )

  const columns = useMemo<GridColDef<typeof rows[number]>[]>(
    () => [
      {
        field: 'amount',
        headerName: 'Amount',
        width: 100,
        display: 'flex',
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        display: 'flex',
        renderCell: (params) => (
          <div>
            <Chip
              size='small'
              sx={{
                color: 'white',
                backgroundColor: getBillEventStatusColor(params.row.status),
                height: 'auto',
                p: '2px',
                borderRadius: '16px',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
                borderColor: 'transparent',
              }}
              label={params.row.status}
            />
          </div>
        ),
      },
      {
        field: 'baseRunId',
        headerName: 'Base Run',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'stepRunId',
        headerName: 'Step Run',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 200,
        display: 'flex',
      },
      {
        field: 'multiplier',
        headerName: 'Quantity',
        width: 150,
        display: 'flex',
        renderCell: (params) => {
          const multiplier = params.row.timeTrackingEnabled
            ? secondsToHMS(params.row.multiplier / 1000)
            : params.row.multiplier

          if (params.row.timeTrackingEnabled) return <div>{multiplier}</div>

          return <div>{`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`}</div>
        },
        valueGetter: (_, row) => {
          const multiplier = row.timeTrackingEnabled
            ? secondsToHMS(row.multiplier / 1000)
            : row.multiplier

          if (row.timeTrackingEnabled) return multiplier

          return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        display: 'flex',
      },
    ],
    []
  )

  return (
    <Stack
      sx={{
        margin: 2,
        boxSizing: 'border-box',
        overflow: 'auto',
      }}
      direction='column'>
      <DataGridPro
        disableRowSelectionOnClick
        sx={{ overflow: 'auto' }}
        slots={{
          ...adminTheme.components?.MuiDataGrid?.defaultProps?.slots,
          toolbar: null,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rows={rows}
        columns={columns}
        pageSizeOptions={[10]}
        rowSelectionModel={selectedBillEventIds}
        onRowSelectionModelChange={(newRowModelSelection) => {
          setSelectedBillEventIds(newRowModelSelection as string[])
          setSelectedBillEventParentRows(!isEmpty(newRowModelSelection) ? [parentRowId] : [])
        }}
      />
    </Stack>
  )
}

export { BillEvents }
