import MuiAlert, { type AlertColor, AlertProps } from '@mui/material/Alert'
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar'
import { forwardRef, type ReactNode, type SyntheticEvent } from 'react'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const CustomSnackbar = ({
  open,
  message,
  severity,
  onClose,
}: {
  open: boolean
  message: ReactNode
  severity: AlertColor
  onClose: (event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => void
}) => (
  <Snackbar
    key={String(message)}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={open}
    autoHideDuration={6000}
    onClose={onClose}>
    <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
)
