import { getPaymentCycles } from '@invisible/common/date'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import { MouseEvent, useEffect, useState } from 'react'

const CYCLE_MENU_ITEM_HEIGHT = 48

export const CycleSelectionMenu = ({
  selectedIndex,
  updateRouterParam,
  onCycleChange,
}: {
  selectedIndex?: number
  updateRouterParam?: boolean
  onCycleChange: (start: Date, end: Date, selectedIndex: number) => void
}) => {
  const router = useRouter()
  const [cycleMenuAnchor, setCycleMenuAnchor] = useState<HTMLElement | null>(null)
  const cycleMenuOpen = Boolean(cycleMenuAnchor)
  const [selectedCycleIndex, setSelectedCycleIndex] = useState(selectedIndex ?? 1)
  const selectedCycle = getPaymentCycles()[selectedCycleIndex]

  const handleCycleMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    setCycleMenuAnchor(e.currentTarget)
  }

  const handleCycleMenuClose = () => setCycleMenuAnchor(null)

  const handleCycleMenuItemClick = (_: MouseEvent<HTMLElement>, index: number) => {
    setSelectedCycleIndex(index)
    onCycleChange(
      getPaymentCycles()[index].start.toDate(),
      getPaymentCycles()[index].end.toDate(),
      index
    )
    if (updateRouterParam) {
      router.query = { ...router.query, cycleIndex: String(index) }
      router.replace(
        {
          query: {
            ...router.query,
          },
        },
        undefined,
        { shallow: true }
      )
    }
    setCycleMenuAnchor(null)
  }

  // We want to trigger the onCycleChange callback ONLY on initial render hence an empty dependency array
  useEffect(() => {
    onCycleChange(selectedCycle.start.toDate(), selectedCycle.end.toDate(), selectedCycleIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex'>
      <Button
        id='cycle-menu-button'
        aria-haspopup
        aria-controls={cycleMenuOpen ? 'cycle-menu' : undefined}
        aria-expanded={cycleMenuOpen ? 'true' : undefined}
        endIcon={cycleMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleCycleMenuClick}>
        {`${selectedCycle.start.format('MMMM Do')} - ${selectedCycle.end.format('MMMM Do')} ${
          selectedCycleIndex === 0 ? '(Current)' : ''
        }`}
      </Button>
      <Menu
        id='cycle-menu'
        anchorEl={cycleMenuAnchor}
        open={cycleMenuOpen}
        onClose={handleCycleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'cycle-menu-button',
        }}
        PaperProps={{
          style: {
            maxHeight: CYCLE_MENU_ITEM_HEIGHT * 4.5,
            minWidth: '20ch',
            maxWidth: 'fit-content',
          },
        }}>
        {getPaymentCycles().map((cycleRange, index) => (
          <MenuItem
            key={index}
            selected={selectedCycleIndex === index}
            onClick={(e) => handleCycleMenuItemClick(e, index)}>
            <Typography variant='inherit'>
              {`${cycleRange.start.format('MMMM Do')} - ${cycleRange.end.format('MMMM Do')}`}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
