import type { ReactNode } from 'react'

export const CustomHeader = ({ title, children }: { title?: string; children?: ReactNode }) => (
  <div className='flex'>
    <div>
      <div className='text-theme-main'>{title}</div>
      {/* Subtitle Component */}
      {children}
    </div>
  </div>
)
