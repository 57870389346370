import { IOrdering } from '@invisible/concorde/gql-client'
import type { PrismaAll, TBillEventSource } from '@invisible/ultron/prisma'
import type { GridSortDirection } from '@mui/x-data-grid-pro'

type IFormattedBillEventStatus = 'Unchecked' | 'Ready To Send' | 'Sent To Finance'

const getBillPaymentStatusLabel = (status?: PrismaAll.BillPaymentStatus | string | null) => {
  switch (status) {
    case 'approved':
      return 'Ready to Send'
    case 'partially_drafted':
      return 'Partially Checked'
    case 'drafted':
      return 'Sent to Finance'
    case 'drafting':
      return 'Drafting'
    default:
      return 'Unchecked'
  }
}

const getBillPaymentStatusColor = (status?: PrismaAll.BillPaymentStatus | string | null) => {
  switch (status) {
    case 'approved':
      return '#018bd9'
    case 'partially_drafted':
      return '#f97316'
    case 'drafted':
      return '#218712'
    case 'drafting':
      return '#018bd9'
    default:
      return '#bf0c0c'
  }
}

const getBillEventStatusLabel = (status?: PrismaAll.BillEventStatus | null) => {
  if (!status || status === 'unapproved') return 'Unchecked' as const
  if (status === 'pre_approved') return 'Ready To Send' as const
  if (status === 'drafted') return 'Sent To Finance' as const
}

const getBillEventStatusColor = (status?: IFormattedBillEventStatus | null) => {
  if (!status || status === 'Unchecked') return '#bf0c0c'
  if (status === 'Ready To Send') return '#018bd9'
  if (status === 'Sent To Finance') return '#218712'
}

const getDataSourceOptionLabel = (source: TBillEventSource) => {
  switch (source) {
    case 'csv_upload':
      return 'CSV Upload'
    default:
      return 'On-Platform'
  }
}

const getAgentStatusOptionLabel = (value?: boolean | string) => {
  switch (value) {
    case true:
      return 'Inactive'
    case false:
      return 'Active'
    default:
      return 'All'
  }
}

const getAgentStatusChipStyle = () => ({
  backgroundColor: '#f0f0f0',
})

const getSortDirection = (sort: GridSortDirection) => {
  switch (sort) {
    case 'asc':
      return IOrdering.Asc
    case 'desc':
      return IOrdering.Desc
    default:
      return null
  }
}

export {
  getAgentStatusChipStyle,
  getAgentStatusOptionLabel,
  getBillEventStatusColor,
  getBillEventStatusLabel,
  getBillPaymentStatusColor,
  getBillPaymentStatusLabel,
  getDataSourceOptionLabel,
  getSortDirection,
}
